import React, { useState, useContext } from "react";
import {
  Alarm,
  NewAlarm,
  postAlarm,
  putAlarm,
} from "../shared/API";
import { Form, FormField, Message } from "semantic-ui-react";
import * as R from "ramda";
import moment from "moment";
import { AppContext, IAppContext } from "../AppContext";
import Calendar from "rc-calendar";
import DatePicker from "rc-calendar/lib/Picker";
import elGR from "rc-calendar/lib/locale/el_GR";
import "rc-calendar/assets/index.css";
import { getAuth } from '../Utilities';

type FormProps = {
  model: Alarm | null;
  triggerOnSuccess?: () => void;
};

interface FormState {
  model: NewAlarm | Alarm;
  loading: boolean;
  showError: boolean;
  errorMessage: string;
  showMessage: boolean;
  formMessages: "success" | "error" | "notshowing";
}
        // style={{ marginBottom: 20 }}

export const AlarmForm = (props: FormProps) => {
  const initState: FormState = {
    model: R.isNil(props.model)
      ? {
        clientName: "",
        mobile: "",
        note: "",
        latestInstallationDate: new Date(),
        latestInstallationNote: "",
        ah72: 0,
        ah23: 0,
        ah12: 0
      }
      : props.model,
    formMessages: "notshowing",
    loading: false,
    showError: false,
    errorMessage: "",
    showMessage: false
  };

  let [st, setSt] = useState(R.clone(initState));
  const appCtx = useContext(AppContext) as IAppContext;
  const handleDateChange = (date: any) =>
    setSt({
      ...st,
      model: { ...st.model, latestInstallationDate: moment(date).toDate() }
    });

  const clearFormMessages = () => {
    if (st.formMessages !== "notshowing") {
      setSt({ ...st, formMessages: "notshowing" });
    }
  };

  const handleClientNameChange = (e: any) =>
    setSt({ ...st, model: { ...st.model, clientName: e.target.value } });

  const handleMobileChange = (e: any) =>
    setSt({ ...st, model: { ...st.model, mobile: e.target.value } });

  const handleCommentChange = (e: any) =>
    setSt({ ...st, model: { ...st.model, note: e.target.value } });

  const handleAh72Change = (e: any) =>
    setSt({ ...st, model: { ...st.model, ah72: Number(e.target.value) } });

  const handleAh36Change = (e: any) =>
    setSt({ ...st, model: { ...st.model, ah23: Number(e.target.value) } });

  const handleAh12Change = (e: any) =>
    setSt({ ...st, model: { ...st.model, ah12: Number(e.target.value) } });

  const submit = () => {
    const newInst: NewAlarm = { ...st.model };
    if (R.isNil(props.model)) {
      postAlarm(newInst, getAuth()).then((response) => {
        setSt({ ...st, formMessages: "success" });
        appCtx.getAlarmsCtx();
        if (!!props.triggerOnSuccess) {
          props.triggerOnSuccess();
        }
      });
    } else {
      putAlarm({ ...st.model } as Alarm, getAuth()).then((response) => {
        setSt({ ...st, formMessages: "success" });
        appCtx.getAlarmsCtx();
        if (!!props.triggerOnSuccess) {
          props.triggerOnSuccess();
        }
      });
    }
  };

  const calendar = (
    <Calendar
      locale={elGR}
      // disabledDate={disabledDate}
      value={moment(st.model.latestInstallationDate)}
      onChange={date => {
        handleDateChange(date);
      }}
    />
  );
  const dateFormat = "DD/MM/YYYY";

  return (
    <Form
      success={st.formMessages === "success"}
      error={st.formMessages === "error"}
    >
      {/* <h4 className="dividing">Στοιχεία πελάτη</h4> */}
      <Form.Group widths="equal">

        <Form.Input
          fluid
          label="Ονομ/μο πελάτη"
          placeholder="Ονομ/μο πελάτη"
          icon="user"
          iconPosition="left"
          value={st.model.clientName}
          onChange={handleClientNameChange}
          onFocus={clearFormMessages}
        />
        <Form.Input
          fluid
          label="Κινητό"
          placeholder="Κινητό"
          icon="mobile"
          iconPosition="left"
          value={st.model.mobile}
          onFocus={clearFormMessages}
          onChange={handleMobileChange}
        />
      </Form.Group>
      <h4 className="dividing">Στοιχεία συναγερμού</h4>

      <Form.Input
        fluid
        label="Περιγραφή"
        placeholder="π.χ. Διεύθυνση, οικία ή κατάστημα κλπ"
        value={st.model.note}
        onChange={handleCommentChange}
        onFocus={clearFormMessages}
      />

      <h4>Μπαταρίες</h4>
      <Form.Group widths="equal">
        <Form.Input
          fluid
          label="12V 7.2Ah"
          placeholder="12V 7.2Ah"
          value={st.model.ah72}
          onChange={handleAh72Change}
          onFocus={clearFormMessages}
        />
        <Form.Input
          fluid
          label="12V 2.3Ah"
          placeholder="12V 2.3Ah"
          value={st.model.ah23}
          onChange={handleAh36Change}
          onFocus={clearFormMessages}
        />
        <Form.Input
          fluid
          label="12V 1.2Ah"
          placeholder="12V 1.2Ah"
          value={st.model.ah12}
          onChange={handleAh12Change}
          onFocus={clearFormMessages}
        />
      </Form.Group>
      {
        props.model === null ?
          <FormField>
            <label>Ημερομηνία αρχικής εγκατάστασης</label>
            {/* date select */}

            <DatePicker
              required
              animation="slide-up"
              calendar={calendar}
              value={moment(st.model.latestInstallationDate)}
            // style={props.style}
            >
              {() => (

                <input
                  // style={{ width: 110 }}
                  readOnly
                  disabled={false}
                  value={st.model.latestInstallationDate && moment(st.model.latestInstallationDate).format(dateFormat)}
                  placeholder="ημερομηνία εγκατάστασης"
                  onFocus={clearFormMessages}
                />
              )}
            </DatePicker>
          </FormField> : null
      }
      <Message
        error
        header="Ανεπιτυχής καταχώρηση"
        content="Κάτι δεν πήγε καλά!"
      />
      <Message
        success
        header="Eπιτυχής καταχώρηση"
        // style={{ marginBottom: 20 }}
        content={
          !R.isNil(props.model)
            ? "Οι αλλαγές αποθηκεύτηκαν επιτυχώς"
            : "Η εγκατάσταση καταχωρήθηκε"
        }
      />
      <button
        className="fluid"
        onClick={submit}
      >
        {!R.isNil(props.model) ? "Ενημέρωση" : "Καταχώρηση"}
      </button>
    </Form>
  );
};
