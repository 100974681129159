import Axios, { AxiosPromise } from "axios";
import moment from "moment";

// ****************************************
// TYPE DEFINITIONS
// ****************************************

// Authorization header

export interface AuthorizationHeader {
  headers: {
    'Authorization': string
  }
}

// Login

export interface UserCredentials {
  username: string,
  password: string
}

type Plan = "Free" | "Pro";

export interface NewUserInfo {
  email: string;
  password: string;
}

export interface UserInfo {
  email: string;
  emailIsVerified: boolean;
  plan: Plan;
  licenseExpires: Date | null;
}

export interface AuthInfo {
  jwt: string;
  userInfo: UserInfo;
}

// Alarms

export interface NewAlarm {
  clientName: string;
  mobile: string;
  latestInstallationDate: Date; // 'installation' refers to battery installation
  latestInstallationNote: string;
  note: string | null;
  ah72: number;
  ah23: number;
  ah12: number;
}

export interface Alarm extends NewAlarm {
  id_: number;
  isActive: boolean;
  userId: number;
}

export interface NewLogEntry {
  installationDate: Date;
  installationNote: string;
}

export interface LogEntry extends NewLogEntry {
  id_: number;
  userId: number;
  alarmId: number;
}

type renewal = "viva" | "key"

export interface SubscriptionRenewalRequest {
  method: renewal;
  payload: string;
  userId: number;
}

// ****************************************
// HTTP API call methods
// ****************************************

// AXIOS config
const CAxios = Axios.create({
  // baseURL: "http://localhost:3030/api/",
  baseURL: "https://app.logit.gr/api/",
  timeout: 6000,
});

// ALARMS endpoint
export const getAlarms = (header: AuthorizationHeader): AxiosPromise<Array<Alarm>> =>
  CAxios.get("alarms", header);

export const getAlarmById = (id: number, header: AuthorizationHeader): AxiosPromise<Alarm> =>
  CAxios.get(`alarms/${id}`, header);

export const postAlarm = (newAlarm: NewAlarm, header: AuthorizationHeader) => {
  const reqBody = ({ ...newAlarm, latestInstallationDate: moment(newAlarm.latestInstallationDate).format('YYYY-MM-DD') });
  return CAxios.post("alarms", reqBody, header);
}

export const putAlarm = (updatedAlarm: Alarm, header: AuthorizationHeader) => {
  const reqBody = ({ ...updatedAlarm, latestInstallationDate: moment(updatedAlarm.latestInstallationDate).format('YYYY-MM-DD') });
  return CAxios.put("alarms", reqBody, header);
}

export const deleteInstallation = (id: number, header: AuthorizationHeader) =>
  CAxios.delete(`alarms/${id}`, header);

export const getAlarmLogEntries = (alarmId: number, header: AuthorizationHeader): AxiosPromise<Array<LogEntry>> =>
  CAxios.get(`alarms/${alarmId}/logEntries`, header);

export const postLogEntry = (newLogEntry: NewLogEntry, alarmId: number, header: AuthorizationHeader) => {
  const newLogEntryReq = ({ ...newLogEntry, installationDate: moment(newLogEntry.installationDate).format('YYYY-MM-DD') });
  return CAxios.post(`alarms/${alarmId}/logEntries/`, newLogEntryReq, header);
}

export const putLogEntry = (logEntry: LogEntry, header: AuthorizationHeader) => {
  const logEntryReq = ({ ...logEntry, installationDate: moment(logEntry.installationDate).format('YYYY-MM-DD') });
  return CAxios.put(`alarms/${logEntry.alarmId}/logEntries/`, logEntryReq, header);
}

export const deleteLogEntry = (logEntryId: number, header: AuthorizationHeader) => (
  CAxios.delete(`alarms/logEntries/${logEntryId}`, header)
);

// USER endpoint
export const signup = (newUserInfo: NewUserInfo): AxiosPromise<number> => // returns a promise of the user id
  CAxios.post('user/', newUserInfo);

export const updatePasswordWithToken = (newpassword: string, token: string) => {
  const reqBody = { token: token, newPass: newpassword };
  return CAxios.post('user/updatepasswordwithtoken/', reqBody);
}

export const postVerifyEmail = (token: string) =>
  CAxios.post(`user/verifyemail/${token}`);

export const updatePassword = (oldPass: string, newPass: string, header: AuthorizationHeader) => (
  CAxios.put(`/user/updatepass`, { oldPass, newPass }, header)
);

export const requestReset = (email: string) => (
  CAxios.post(`/user/passwordresetrequest`, { email })
);

export const requestNewVerificationEmail = (header: AuthorizationHeader) => {
  CAxios.post(`/user/sendEmailVerif/`, {}, header);
}

// LOGIN endpoint
export const loginRequest = ({ username, password }: UserCredentials): AxiosPromise<AuthInfo> => (
  CAxios.post('/login', { username, password })
);

// SUBSCRIPTION RENEWAL

export const requestSubscriptionRenewal = (req: SubscriptionRenewalRequest) =>
  CAxios.post("subscriptionrenewal", req);

// VIVA request

const vivaDemo = "https://demo.vivapayments.com/"
const vivaProd = "https://www.vivapayments.com/"

export const requestPaymentOrder = (header: AuthorizationHeader) => {
  CAxios.post("/user/paymentorder", {}, header);
}

const baseUrl = vivaDemo;
// // const baseUrl = vivaProd;

const Viva = Axios.create({
  baseURL: baseUrl,
  // timeout: 6000,
});

export interface PaymentOrderRequest {
  amount: number;
  email: string;
}

export interface VivaResponse {
  orderCode: number;
  errorCode: number; // 0 for success
  errorText: string;
  timeStamp: string;
}

export const postPaymentOrder = (request: PaymentOrderRequest): AxiosPromise<VivaResponse> =>
  Viva.post('/api/orders', request);

export const toVivaCheckoutURL = (orderCode: number) => `${baseUrl}web/checkout?ref=${orderCode}`;
