import React, { useState } from "react";
import { Popup, Button } from "semantic-ui-react";
import { getAuth } from "../Utilities";

const DeleteButton = ({ triggerOnSuccess, id, deleteMethod, message }) => {
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(false);
  const [completed, setCompleted] = useState(false);

  const del = () => {
    setDeleting(true);
    deleteMethod(id, getAuth())
      .then(() => {
        setDeleting(false);
        triggerOnSuccess();
        setCompleted(true);
      })
      .catch((error) => {
        setDeleting(false);
        setError(true);
      });
  };

  return (
    <Popup
      flowing
      hoverable
      on="click"
      trigger={<i className="trash alternate large icon" />}
      position="bottom right"
      content={
        completed ? null : (
          <DeleteConfirmation
            deleteMethod={del}
            id={id}
            message={message}
            error={error}
          />
        )
      }
    />
  );
};

const DeleteConfirmation = (props) => {
  const { completed, error, message, deleting, deleteMethod } = props;
  if (!completed && !error) {
    return (
      <div style={{ maxWidth: 200 }}>
        {message !== "" ? <p>{message}</p> : null}
        <Button color="red" loading={deleting} onClick={deleteMethod}>
          Επιβεβαίωση διαγραφής
        </Button>
      </div>
    );
  } else if (error) {
    return <p>Δεν ήταν δυνατή η διαγραφή της καταχώρησης</p>;
  }
};

export default DeleteButton;
