import React from "react";

interface TabSelectProps<T> {
  options: Array<T>;
  onSelect: (option: T) => void;
  disabled: boolean;
  selected: string;
}

export const TabSelect = (props: TabSelectProps<any>) => {
  return (
    <div className="main-selectors">
      {props.options.map((option, i) => (
        <div
          className={`selector ${option === props.selected ? "active" : ""}`}
          onClick={() => props.onSelect(option)}
          key={i}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

TabSelect.defaultProps = {
  disabled: false
};
