import React, { useContext } from "react";
import { Modal, Dropdown } from "semantic-ui-react";
import { UpdatePasswordForm } from "./UpdatePasswordForm";
import { AppContext, IAppContext } from '../AppContext';
import * as API from '../shared/API';
import { getAuth } from '../Utilities';

export const AppTopBar = () => {
  const appCtx = useContext(AppContext) as IAppContext;
  return (
    <div className="app-top-bar">
      <div className="app-logo">
        <div className="logo-title">Logit</div>
      </div>
      <div className="arrow-right" />
      <div className="right-menu">
        <Dropdown icon='user outline'>
          <Dropdown.Menu direction="left">
            <Dropdown.Item>
              <Modal
                trigger={<span><i className="lock icon"/>Αλλαγή κωδικού</span>}
                size="tiny"
              >
                <Modal.Header>Αλλαγή κωδικού πρόσβασης</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <UpdatePasswordForm />
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </Dropdown.Item>
            <Dropdown.Item>
              <span onClick={appCtx.handleLogout}>
                <i className="logout icon" />Αποσύνδεση
              </span>
            </Dropdown.Item>
            <Dropdown.Item>
              <span onClick={() => API.requestPaymentOrder(getAuth())}>
                <i className="logout icon" /> Upgrade account
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>

        </Dropdown>


      </div>
    </div>
  );
};
