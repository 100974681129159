import React, {useState, ReactChild, useContext} from 'react';
import { AppContext, IAppContext } from '../AppContext';

const FooterNotification = () => {
    const appCtx = useContext(AppContext) as IAppContext;

    if (appCtx.footerNotifContent === "") {
        return null;
    } else {
        return (
            <div className="footer-notification">
                <div className="close"><i className="close icon" onClick={appCtx.clearFooterNotif}/></div>
                <>
                    {appCtx.footerNotifContent}
                </>
            </div>
        )
    }
}

export default FooterNotification;