import React, { useState } from 'react';

import * as API from '../shared/API';
import { getAuth } from '../Utilities';
import { Button, Form, Message } from 'semantic-ui-react';
import * as R from 'ramda';

interface FormValues {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
}

export const UpdatePasswordForm: React.FC<{}> = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [displayError, setDisplayError] = useState(false);
    const [errorMessages, setErrorMessages] = useState([] as Array<string>);
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);

    const errors = {
        notMatching: 'Οι νέοι κωδικοί δεν είναι ίδιοι.',
        emptyField: 'Συμπληρώστε όλα τα πεδία.'
    }

    const handleSubmit = () => {
        setSubmitting(true);
        const errs = [] as Array<string>;
        if (newPassword !== newPasswordConfirm) {
            errs.push(errors.notMatching);
        }
        if (newPasswordConfirm.length === 0 || newPassword.length === 0 || oldPassword.length === 0) {
            errs.push(errors.emptyField);
        }
        if (errs.length > 0) {
            setErrorMessages(R.concat(errs, errorMessages));
            setDisplayError(true);
            setSubmitting(false);
            return;
        }
        API.updatePassword(
            oldPassword,
            newPassword,
            getAuth()
        )
            .then((res: any) => {
                setSuccess(true);
            })
            .catch((err: any) => {
                if (err.response?.status === 401) {
                    setErrorMessages(R.append('Ο ισχύων κωδικός δεν είναι έγκυρος.', errorMessages));
                    setDisplayError(true);
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    const clearMessages = () => {
        setDisplayError(false);
        setErrorMessages([]);
        setSuccess(false);
    }

    const handleOldPasswordChange = (e: any) => {
        setOldPassword(e.target.value);
    }

    const handleNewPasswordConfirmChange = (e: any) => {
        setNewPasswordConfirm(e.target.value);
    }

    const handleNewPasswordChange = (e: any) => {
        setNewPassword(e.target.value);
    }

    const errorsLs = () => {
        const toLi = (msg: string) => <li>{msg}</li>
        return (
            <ul>
                {errorMessages.map(toLi)}
            </ul>
        );
    }

    return (
        <Form
            error={displayError}
            success={success}
        >
            <Form.Input
                label="Ισχύων κωδικός"
                value={oldPassword}
                onChange={handleOldPasswordChange}
                onFocus={clearMessages}
            />

            <Form.Input
                label="Νέος κωδικός"
                value={newPassword}
                onChange={handleNewPasswordChange}
                onFocus={clearMessages}
            />

            <Form.Input
                label="Επιβεβαίωση νέου κωδικού"
                value={newPasswordConfirm}
                onChange={handleNewPasswordConfirmChange}
                onFocus={clearMessages}
            />
            <button
                className="fluid"
                // loading={submitting}
                onClick={handleSubmit}>
                Αλλαγή κωδικού
			</button>
            <Message
                error
                header="Αποτυχία"
                content={errorsLs}
            />
            <Message
                success
                header="Επιτυχία"
                content="Ο κωδικός σας έχε αλλάξει"
            />
        </Form>
    );
};
