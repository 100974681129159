import React from 'react';
import * as API from '../shared/API'
import { getAuth } from '../Utilities';

const EmailVerifNotification = () => {
    const requestNewVerificationEmail = () => {
        API.requestNewVerificationEmail(getAuth());
    }
    return (
        <>
            <p>Σας έχει σταλεί email για την επιβεβαίωση του email σας.</p>
            <p>Αν δεν το έχετε λάβει, ελέγξτε τα spam ή ζητήστε να γίνει <button className="secondary" onClick={() => requestNewVerificationEmail()}>νέα αποστολή</button></p>
        </>
    )
}

export default EmailVerifNotification;