import React from 'react';
import { Form, FormInput } from 'semantic-ui-react';
import { useParams } from 'react-router';
import '../reset.css';
import { updatePasswordWithToken } from '../shared/API';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const PasswordResetView = () => {
    const { token } = useParams<{ token: string }>();

    interface FormValues {
        passwordA: string,
        passwordB: string,
    }

    const formInitValues: FormValues = {
        passwordA:'',
        passwordB:''
    }

    const handleSubmit = (pass:string) => {
        updatePasswordWithToken(pass, token ? token : '')
            .finally(() => {
                formik.setSubmitting(false);
            });
    }

    const validate = (values: FormValues):object => {
        let errors: any = {};
        if (values.passwordA !== values.passwordB) {
            errors.passwordB = "Οι κωδικοί δεν είναι ίδιοι"
            errors.passwordA = "Οι κωδικοί δεν είναι ίδιοι"
        }    
        return errors;
    }

    const formik = useFormik({
        initialValues: formInitValues,
        validationSchema: Yup.object({
            passwordA:Yup.string()
                .min(6, 'Ο κωδικός πρέπει να έχει τουλάχιστον 6 χαρακτήρες')
                .required('Απαραίτητο πεδίο'),
            passwordB:Yup.string()
                .min(6, 'Ο κωδικός πρέπει να έχει τουλάχιστον 6 χαρακτήρες')
                .required('Απαραίτητο πεδίο')
        }),
        validate: validate,
        onSubmit: ({passwordA, passwordB}) => handleSubmit(passwordA)
    });


    return (
        <div className="login">
            <h1>Ορισμός νέου κωδικού πρόσβασης</h1>
            <div className="form-container">
                <Form onSubmit={formik.handleSubmit}>
                    <FormInput
                        label="Νέος κωδικός πρόσβασης"
                        name="passwordA"
                        onChange={formik.handleChange}
                        value={formik.values.passwordA}
                        error={formik.touched.passwordA && formik.errors.passwordA}
                    />
                    <FormInput
                        label="Νέος κωδικός πρόσβασης (ξανά)"
                        name="passwordB"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.passwordB}
                        error={formik.touched.passwordB && formik.errors.passwordB}
                    />
                    <button type="submit">
                        Υποβολή
                    </button>
                </Form>
            </div>
        </div>
    );
}

export default PasswordResetView;