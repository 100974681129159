import React, { ReactNode, useState } from "react";
import { Modal } from "semantic-ui-react";
import { LogEntry } from "../shared/API";
import { LogEntryForm } from "./LogEntryForm";

interface ModalProps {
  trigger: ReactNode;
  model: LogEntry | null;
  alarmId: number;
  callback: () => void;
}

export const LogEntryFormModal = (props: ModalProps) => {
  let [open, setOpen] = useState(false);
  const triggerWrapper = (
    <div onClick={() => setOpen(true)}>{props.trigger}</div>
  );

  const triggerOnSuccess = () => {
      props.callback();
      setOpen(false);
  }

  return (
    <Modal
      trigger={triggerWrapper}
      closeIcon
      refs="formModal"
      open={open}
      onClose={() => setOpen(false)}
      size="tiny"
    >
      <Modal.Header><h4>{props.model ? 'ΕΠΕΞΕΡΓΑΣΙΑ ΑΝΤΙΚΑΤΑΣΤΑΣΗΣ' : 'ΝΕΑ ΑΝΤΙΚΑΤΑΣΤΑΣΗ'}</h4></Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <LogEntryForm
            model={props.model}
            triggerOnSuccess={triggerOnSuccess}
            alarmId={props.alarmId}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
