import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import PasswordResetView from './PasswordResetView';
import EmailVerificationView from "./EmailVerificationView";
import AlarmView from './AlarmView';
import { AppContextProvider } from '../AppContext';
import ProtectedRoute from './ProtectedRoute';
import { AlarmsList } from './AlarmsList';
import "../App.css";
import '../nord.css';
import Landing from './Landing';
import LoginForm from './LoginForm';
import { RequestResetForm } from './RequestResetForm';
import SignUpForm from './SignUpForm';
import FooterNotification from './FooterNotification';

const renderApp = () => {
    return (
        <BrowserRouter>
            <Switch>
                <AppContextProvider>
                    <div className="appcontainer">
                    <main>
                        <Route 
                            exact path='/login' 
                            component={() => <Landing><LoginForm/></Landing>} 
                        />
                        <Route 
                            exact path='/requestreset' 
                            component={() => <Landing><RequestResetForm/></Landing>} 
                        />
                        <Route 
                            exact path='/signup' 
                            component={() => <Landing><SignUpForm /></Landing>} 
                        />
                        <Route 
                            exact path='/reset/:token' 
                            component={() => <PasswordResetView />}
                        />
                        <Route 
                            exact path='/verifyemail/:token'
                            component={() => <EmailVerificationView />}
                        />
                        <Route
                            exact path='/subscription/:transactionId/:userId'
                            component={() => <p>hey!</p>}
                        />
                        <Route
                            exact path='/payment_success'
                            component={() => <p>hey!</p>}
                        />
                        <Route
                            exact path='/payment_failure'
                            component={() => <p>hey!</p>}
                        />
                        <ProtectedRoute
                            exact path='/'
                            component={AlarmsList}
                        />
                        <ProtectedRoute
                            exact path='/alarm/:idstr'
                            component={AlarmView}
                        />
                    </main>
                    <FooterNotification />
                    <footer>&copy;2020 Logit v0.2.1</footer>
                    </div>
                </AppContextProvider>
            </Switch>
        </BrowserRouter>
    )
}

export default renderApp;