import React, { useContext } from 'react';
import { AppContext, IAppContext } from '../AppContext';
import { Redirect } from 'react-router';

const Landing: React.FC = ({ children }: any) => {

    const appCtx = useContext(AppContext) as IAppContext;

    return (
        <div id='grid-container'>
            <section className='logo-container'>
                <h1>Logit</h1>
                <b id="logo-line"></b>
                <b id="logo-line2"></b>
            </section>
            <section className='login-form-container'>
                {!appCtx.loggedIn && children}
                {appCtx.loggedIn && <Redirect to='/' />}
            </section>
        </div>
    );
}

export default Landing;