import React, { useContext, useState } from 'react';
import { useParams, Redirect } from 'react-router';
import { AppContext, IAppContext } from '../AppContext';
import { Alarm, LogEntry, getAlarmLogEntries, deleteInstallation, deleteLogEntry, AuthorizationHeader } from '../shared/API';
import { Link } from 'react-router-dom';
import { AppTopBar } from './AppTopBar';
import { getAuth } from '../Utilities';
import DeleteButton from './DeleteButton';
import moment from 'moment';
import { AlarmFormModal } from './AlarmFormModal';
import { LogEntryFormModal } from './LogEntryFormModal';
import * as R from 'ramda';

enum ComStatus {
    Idle,
    Fetching,
    Success,
    Failure
}

type AlarmLog = Array<LogEntry>

interface IConditional {
    children: React.ReactNode;
    condition: boolean;
    alternative?: any;
}

const Conditional = (props: IConditional) => {
    if (props.condition) {
        return props.children;
    } else {
        return props.alternative ? props.alternative : null;
    }
};

const AlarmView = () => {
    const { idstr } = useParams<{ idstr: string }>();
    const appCtx = useContext(AppContext) as IAppContext;
    const alarm: Alarm | null = appCtx.alarms.filter(({ id_ }) => id_ === Number(idstr))[0];
    const initAlarmLog: AlarmLog = [];
    const [logEntries, setLogEntries] = useState(initAlarmLog);
    const [fetching, setFetching] = useState(ComStatus.Idle);
    const [deleted, setDeleted] = useState(false);

    const fetchData = () => {
        setFetching(_ => ComStatus.Fetching);
        getAlarmLogEntries(Number(idstr), getAuth())
            .then((resp) => {
                setLogEntries(_ => resp.data);
                setFetching(_ => ComStatus.Success);
            })
            .catch((e) => {
                setFetching(_ => ComStatus.Failure)
            })
    }

    React.useEffect(() => {
        if (!R.isNil(alarm)) {
            fetchData();
        }
    }, [alarm]);

    // should redirect to alarms list
    const triggerOnSuccess = () => {
        appCtx.getAlarmsCtx();
        setDeleted(true);
    };

    const logEntry = (logEntry: LogEntry) => {
        const { installationDate: date, installationNote: note } = logEntry;
        return (
            <div key={logEntry.id_} className="logentry-card">
                <div className="logentry-date">{moment(date).format('DD/MM/YYYY')}</div>
                <div className={`logentry-note ${note.length > 0 ? '' : 'no-note'}`}>{note.length > 0 ? logEntry.installationNote : "χωρίς σημείωση/σχόλιο"}</div>
                <div className="logentry-actions">
                    <LogEntryFormModal
                        trigger={<i className="edit large icon" />}
                        alarmId={logEntry.alarmId}
                        callback={fetchData}
                        model={logEntry}
                    />
                    {logEntries.length > 1 ? (
                        <DeleteButton
                            triggerOnSuccess={fetchData}////
                            deleteMethod={deleteLogEntry}
                            id={logEntry.id_}
                            message="ΠΡΟΣΟΧΗ! Η διαγραφή δεν είναι αναστρέψιμη!"
                        />
                    ) : (
                            null
                        )}
                </div>
            </div>
        );
    }

    const batteries =
        <div className="batteries">
            <Conditional condition={alarm.ah72 > 0}>
                <div>{alarm.ah72} x 12V 7.2Αh</div>
            </Conditional>
            <Conditional condition={alarm.ah23 > 0}>
                <div>{alarm.ah23} x 12V 2.3Αh</div>
            </Conditional>
            <Conditional condition={alarm.ah12 > 0}>
                <div>{alarm.ah12} x 12V 1.2Αh</div>
            </Conditional>
        </div>

    return (
        <div className="app-container">
            <AppTopBar />
            <div className="app-content">
                <div className="main-actions">
                    <div className="main-actions-left" style={{ marginBottom: 10 }}>
                        <Link to="/">
                            <button className="secondary">
                                {/* <Icon name="chevron left" /> Συναγερμοί */}
                                <i className="chevron left icon" /> Συναγερμοί
                            </button>
                        </Link>
                    </div>
                </div>
                {deleted && <Redirect to="/" />}
                {!R.isNil(alarm) ? (<>
                    <section className="alarm-card">
                        <div className="alarm-details-actions">
                            <AlarmFormModal
                                trigger={<i className="edit large icon" />}
                                model={alarm}
                            />

                            <DeleteButton
                                triggerOnSuccess={triggerOnSuccess}
                                deleteMethod={deleteInstallation}
                                id={alarm.id_}
                                message="ΠΡΟΣΟΧΗ! Αν διαγράψετε την καταχώρηση θα διαγραφούν όλα τα στοιχεία μαζί με το ημερολόγιο. Η διαδικασία δεν είναι αναστρέψιμη."
                            />
                        </div>
                        <div className="alarm-details">
                            <Info
                                header="Πελάτης"
                                content={alarm.clientName}
                            />
                            <Info
                                header="Τηλ επικοινωνίας"
                                content={alarm.mobile}
                            />
                            <Info
                                header="Μπαταρίες"
                                content={batteries}
                            />
                            <Info
                                header="Σημείωση/Περιγραφή"
                                content={alarm.note ? alarm.note : ""}
                            />
                        </div>
                        <div className="footer">επόμενη αντικατάσταση μπαταριών μέχρι: <span style={{ color: '#2e3440', fontWeight: 'bold' }}>{moment(alarm.latestInstallationDate).add(2, 'years').format('DD/MM/YYYY')}</span></div>

                    </section>
                    <section className="alarm-log">
                        <header>
                            <h3>Ιστορικό αντικαταστάσεων μπαταριών</h3>
                            <LogEntryFormModal
                                model={null}
                                alarmId={alarm.id_}
                                callback={fetchData}
                                trigger={<button style={{ display: 'block', marginLeft: 'auto', marginTop: 20, marginBottom: 20 }}>Νέα αντικατάσταση</button>}
                            />
                        </header>
                        {
                            logEntries ? logEntries.map(logEntry) : <p>δεν βρέθηκαν εγγραφές</p>
                        }
                    </section>
                </>) : (<Redirect to='/' />)}

            </div>
        </div>
    );
}

interface Info {
    header: string;
    content: string | number | JSX.Element;
    icon?: string;
}

const Info = (info: Info) => (
    <section className="info">
        <header className="info-header">{info.header}</header>
        <div className="info-content">{info.content}</div>
    </section>
);


export default AlarmView;
