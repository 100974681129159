import React, { ReactNode, useState } from "react";
import { Modal } from "semantic-ui-react";
import { AlarmForm } from "./AlarmForm";
import { Alarm } from "../shared/API";

interface ModalProps {
  trigger: ReactNode;
  model: Alarm | null;
}

export const AlarmFormModal = (props: ModalProps) => {
  let [open, setOpen] = useState(false);
  const triggerWrapper = (
    <div onClick={() => setOpen(true)}>{props.trigger}</div>
  );
  return (
    <Modal
      trigger={triggerWrapper}
      closeIcon
      refs="formModal"
      open={open}
      onClose={() => setOpen(false)}
      size="tiny"
    >
      <Modal.Header><h4>{!props.model ? 'ΝΕΟΣ ΣΥΝΑΓΕΡΜΟΣ' : 'ΕΠΕΞΕΡΓΑΣΙΑ ΣΥΝΑΓΕΡΜΟΥ'}</h4></Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AlarmForm
            model={props.model}
            triggerOnSuccess={() => setOpen(false)}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
