import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router';
import { AppContext, IAppContext } from '../AppContext';
import { AppTopBar } from './AppTopBar';
// import LoadingView from '../../ui/views/LoadingView';

const ProtectedRoute = ({component: Component,...rest }:any) => {
  const appCtx = useContext(AppContext) as IAppContext;
  let to:string;
  if (!appCtx.loggedIn) {
    to = '/login';
  }
  return (
    <Route
      {...rest}
      render={() => (appCtx.loggedIn ? (
        <>
          <AppTopBar />
          <Component {...rest}/>
        </>
      ) : (
        <Redirect
          to={{ pathname: to }}
        />))
      }
    />);
};

export default ProtectedRoute;