import React, { useState } from 'react';
import { Form, FormField, Message, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { requestReset } from '../shared/API';

export const RequestResetForm = () => {
    let [userEmail, setUserEmail] = useState('');
    let [error, setError] = useState(false);
    let [errorMessage, setErrorMessage] = useState('');
    let [success, setSuccess] = useState(false);

    const handleSubmit = () => {
        requestReset(userEmail)
            .then((response) => {
                console.log('request completed');
            })

    }

    const clearError = () => {
        setError(false)
        setErrorMessage('')
    };

    return (
        <div>
            <h4>Αίτημα νέου κωδικού</h4>
            <p>Θα σας σταλεί email με οδηγίες για να ολοκληρώσετε τη διαδικασία.</p>
            <Form error={error} onSubmit={handleSubmit}>
                <FormField>
                    <label>email</label>
                    <input
                        value={userEmail}
                        onChange={e => setUserEmail(e.currentTarget.value)}
                        onFocus={clearError}
                    />
                </FormField>
                <Message
                    error
                    header="Αποτυχία"
                />
                <Message
                    success
                    header="Αίτημα σε εξέλιξη"
                    content="Ακολουθήστε τις οδηγίες που στείλαμε στο email σας."
                />
                <button className="fluid">
                    Αίτηση νέου κωδικού
            </button>
                <div style={{ marginTop: 20 }}>

                    <Link to='/login'><i className="chevron left icon" /> Πίσω στο login</Link>
                </div>
            </Form>

        </div>
    )
}