import React, { useState, ReactChild } from "react";
import { getAlarms, Alarm } from "./shared/API";
import { getAuth } from './Utilities';
import * as R from 'ramda';
import RenewSubscription from "./components/RenewSubscription";
import EmailVerifNotification from "./components/EmailVerifNotification";

export interface IAppContext {
  alarms: Array<Alarm>;
  loggedIn: Boolean;
  footerNotifContent: ReactChild;
  clearFooterNotif: () => void;
  handleLoginSuccess: () => void;
  handleLogout: () => void;
  getAlarmsCtx: () => void;
  [key: string]: any;
}

export const AppContext = React.createContext({});

export const AppContextProvider: React.FC = ({ children }: any) => {
  const initialContext: IAppContext = {
    alarms: [],
    loggedIn: false,
    footerNotifContent: "",
    clearFooterNotif: () => clearFooterNotif(),
    getAlarmsCtx: () => getAlarms_(),
    handleLoginSuccess: () => handleLoginSuccess_(),
    handleLogout: () => handleLogout_()
  };

  let [appCtx, setCtx] = useState(initialContext);

  const clearFooterNotif = () => setCtx(ctx => ({...ctx, footerNotifContent:""}));

  const getAlarms_ = () =>
    getAlarms(getAuth()).then(response => {
      const fetchedAlarms = response.data.sort(
        (a: Alarm, b: Alarm) =>
          new Date(a.latestInstallationDate).getTime() - new Date(b.latestInstallationDate).getTime()
      );
      setCtx(ctx => ({...ctx, alarms: fetchedAlarms}));
    });

  const handleLoginSuccess_ = () => {
    setCtx(ctx => ({ ...ctx, loggedIn: true }));
  }

  const handleLogout_ = () => {
    sessionStorage.clear(); 
    setCtx(R.clone(initialContext));
  }

  const setFooterNotifContent = (content:ReactChild) => {
    setCtx(ctx => ({...ctx, footerNotifContent: content}));
  }

  React.useEffect(() => {
    if (appCtx.loggedIn) {
      getAlarms_();      
      // getNotifications
      if (sessionStorage.getItem('emailIsVerified') === "false") {
        setFooterNotifContent(<EmailVerifNotification/>);
      }
    }
  }, [appCtx.loggedIn]); 
  return <AppContext.Provider value={appCtx}>{children}</AppContext.Provider>;
};
