import React, { useState, useContext } from 'react';
import '../login.css';
import { Form, Message, FormInput } from 'semantic-ui-react';
import * as API from '../shared/API';
import { AppContext, IAppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import { Login } from '../shared/Schemas'
import { useFormik } from 'formik';

interface LoginFormValues {
    email: string;
    password: string;
}

enum AuthResult {
      InvalidCredentials = "Μη έγκυρα στοιχεία σύνδεσης"
    , ComError = "Αδυναμία επικοινωνίας. Ελέγξτε τη σύνδεση σας ή δοκιμάστε αργότερα"
    , Pending = ""
}

const LoginForm = () => {
    const initValues: LoginFormValues = {
        email: '',
        password: '',
    }

    const appCtx = useContext(AppContext) as IAppContext;

    const [failure, setFailure] = useState(AuthResult.Pending);

    const handleSubmit = (username: string, password: string) => {
        formik.setSubmitting(true);
        API.loginRequest({ username, password })
            .then((response: any) => {
                if (response.data === null) {
                    setFailure(AuthResult.InvalidCredentials);
                } else {
                    sessionStorage.setItem('jwtToken', response.data.jwt);
                    sessionStorage.setItem('email', response.data.userInfo.email);               
                    sessionStorage.setItem('emailIsVerified', JSON.stringify(response.data.userInfo.emailIsVerified));
                    appCtx.handleLoginSuccess();
                }
            })
            .catch((error: any) => {
                if (error.response?.status === 401) {
                    setFailure(AuthResult.InvalidCredentials);
                } else {
                    setFailure(AuthResult.ComError);
                }
            })
            .finally(() => {
                formik.setSubmitting(false);
            })
    }

    const formik = useFormik({
        initialValues: initValues,
        validationSchema: Login,
        onSubmit: ({ email, password }) => handleSubmit(email, password)
    })

    const clearFailure = () => {
        setFailure(AuthResult.Pending);
    }

    return (
        <Form
            error={failure !== AuthResult.Pending}
            onSubmit={formik.handleSubmit}
        >
            <FormInput
                label="email"
                name="email"
                onFocus={clearFailure}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && formik.errors.email}
            />
            <FormInput
                label="κωδικός"
                name="password"
                onFocus={clearFailure}
                error={formik.touched.password && formik.errors.password}
                value={formik.values.password}
                onChange={formik.handleChange}
                type='password'
            />
            <button type="submit"className="fluid">Είσοδος</button>
            <Message
                error
                header="Αποτυχία "
                content={failure}
            />
            <div style={{ marginTop: 20 }}>
                <Link to='/requestreset'>Ξεχάσατε τον κωδικό σας;</Link>
            </div>
            <div style={{ marginTop: 20 }}>
                <Link to='/signup'>
                        Εγγραφή νέου χρήστη
                </Link>
            </div>
        </Form>
    );
}

export default LoginForm;