import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import * as API from '../shared/API';
import { SignUp } from '../shared/Schemas';
import { FormInput, Form, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { AppContext, IAppContext } from '../AppContext';

enum SignupStatus {
      ComError = "Αδυναμία επικοινωνίας. Ελέγξτε τη σύνδεση σας ή δοκιμάστε αργότερα"
    , AccountExists = "Υπάρχει ήδη λογαριασμός με το email που εισαγάγατε" 
    , Pending = ""
}

const SignUpForm = () => {
    const [failure, setFailure] = useState(SignupStatus.Pending);

    interface FormValues {
        email: string,
        password: string,
        passwordConfirm: string,
    };

    const appCtx = useContext(AppContext) as IAppContext;

    const initFormValues: FormValues = {
        email: '',
        password: '',
        passwordConfirm: ''
    };

    const validate = (values: FormValues): object => {
        let errors: any = {};
        if (values.password !== values.passwordConfirm) {
            errors.password = "Οι κωδικοί δεν είναι ίδιοι";
            errors.passwordConfirm = "Οι κωδικοί δεν είναι ίδιοι";
        }
        return errors;
    }

    const handleSubmit = (email: string, password: string) => {
        const user: API.NewUserInfo = { email, password };
        API.signup(user)
            .then((response) => {
                // handle failure??
                API.loginRequest({ username: formik.values.email, password: formik.values.password })
                    .then((response: any) => {
                        if (response.data === null) {
                            // setFailure(AuthResult.InvalidCredentials);
                            console.log("something went wrong on auto login after signup");
                            
                        } else {
                            sessionStorage.setItem('jwtToken', response.data.jwt);
                            sessionStorage.setItem('email', response.data.userInfo.email);               
                            sessionStorage.setItem('emailIsVerified', JSON.stringify(response.data.userInfo.emailIsVerified));
                            appCtx.handleLoginSuccess();
                        }
                    })
            })
            .catch((error: any)=> {
                if (error.response?.status === 409) {
                    setFailure(SignupStatus.AccountExists);
                } else {
                    setFailure(SignupStatus.ComError)
                }
            })
            .finally(() => {
                formik.setSubmitting(false);
            })
    }

    const formik = useFormik({
        initialValues: initFormValues,
        validationSchema: SignUp,
        validate: validate,
        onSubmit: ({ email, password }) => handleSubmit(email, password)
    })

    const clearSignupStatus = () => {
        setFailure(SignupStatus.Pending);
    }

    return (
        <div>
            <h4>Ξεκινήστε δωρεάν!</h4>
            <p>Δημιουργήστε λογαριασμό χρήστη στο Logit άμεσα, χωρίς καμία οικονομική υποχρέωση!</p>
            <Form 
                error={failure !== SignupStatus.Pending}
                onSubmit={formik.handleSubmit}
            >
                <FormInput
                    label="email νέου χρήστη"
                    name="email"
                    onFocus={clearSignupStatus}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email}
                />
                <FormInput
                    label="κωδικός πρόσβασης"
                    name="password"
                    onFocus={clearSignupStatus}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.touched.password && formik.errors.password}
                />
                <FormInput
                    label="κωδικός προσβασης (επανάληψη)"
                    name="passwordConfirm"
                    onFocus={clearSignupStatus}
                    onChange={formik.handleChange}
                    value={formik.values.passwordConfirm}
                    error={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                />
                <button type="submit">
                    Δημιουργία λογαριασμού
                </button>
                <Message
                    error
                    header="Δεν ήταν δυνατή η δημιουργία λογαριασμού"
                    content={failure}
                />
                <div style={{ marginTop: 20 }}>
                    <Link to='/login'><i className="chevron left icon" /> Πίσω στο login</Link>
                </div>
            </Form>
        </div>
    );
}

export default SignUpForm