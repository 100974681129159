import React, { useState, useEffect } from 'react';
// import { Form, FormField, Button, Message, FormInput } from 'semantic-ui-react';
import * as  API from '../shared/API';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

enum Result { Failure, Success, Pending }

const EmailVerificationView = () => {
    const { token } = useParams<{ token: string }>();
    let [result, setResult] = useState(Result.Pending);

    const req = () =>
        API.postVerifyEmail(token ? token : '')
            .then((response: any) => {
                if (response.data === true) {
                    setResult(Result.Success)
                } else {
                    setResult(Result.Failure)
                }
            }
            )
            .catch(() => {
                setResult(Result.Failure)
            });

    useEffect(() => {
        req();
    }, []);

    const pending = <p>...</p>
    const failure = (
        <section className="card">
            <p>Κάτι πήγε στραβά</p>
            <Link to="/login">
                <button className="secondary">
                    <i className="chevron left icon" /> Πίσω στην εφαρμογή
                </button>
            </Link>
        </section>
    );

    const success = (
        <section className="card">
            <p>Επιβεβαιώθηκε επιτυχώς η εγκυρότητα του email σας.</p>
            <Link to="/login">
                <button className="secondary">
                    Είσοδος στην εφαρμογή <i className="chevron right icon" />
                </button>
            </Link>
        </section>
    );
    return (
        <div style={{maxWidth:"40rem", marginLeft:"auto", marginRight:"auto1"}}>
            {result === Result.Pending
                ? pending
                : result === Result.Failure
                    ? failure
                    : success
            }
        </div>
    );
}

export default EmailVerificationView;