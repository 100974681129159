import * as yup from 'yup';

export const NewItem = yup.object({
    clientName: yup.string()
        .trim()
        .required('Το όνομα του πελάτη είναι απαραίτητο')
        .max(50, 'Το όνομα του πελάτη πρέπει να είναι μέχρι 50 χαρακτήρες'),
    mobile: yup.string()
        .trim()
        .required('Το τηλέφωνο του πελάτη είναι απαραίτητο') // ?
        .max(50, 'Το τηλέφωνο πρέπει να είναι έως 50 χαρακτήρες'),
    note: yup.string()
        .trim()
        .required('Το σχόλιο είναι απαραίτητο') // ?
        .max(250, 'Το σχόλιο πρέπει να είναι έως 250 χαρακτήρες'),
    ah72: yup.number('Ο αριθμός των μπαταριών πρέπει να είναι θετικός ακέραιος')
        .min(0, 'Ο αριθμός των μπαταριών πρέπει να είναι θετικός ακέραιος')
        .integer('Ο αριθμός των μπαταριών πρέπει να είναι θετικός ακέραιος'),
    ah36: yup.number('Ο αριθμός των μπαταριών πρέπει να είναι θετικός ακέραιος')
        .min(0, 'Ο αριθμός των μπαταριών πρέπει να είναι θετικός ακέραιος')
        .integer('Ο αριθμός των μπαταριών πρέπει να είναι θετικός ακέραιος'),
    ah12: yup.number('Ο αριθμός των μπαταριών πρέπει να είναι θετικός ακέραιος')
        .min(0, 'Ο αριθμός των μπαταριών πρέπει να είναι θετικός ακέραιος')
        .integer('Ο αριθμός των μπαταριών πρέπει να είναι θετικός ακέραιος'),
});

export const NewLogEntry = yup.object({
    installationNote: yup.string()
        .max(250, 'To σχόλιο πρέπει να είναι έως 250 χαρακτήρες')
});

export const ForgotPassword = yup.object({
    email: yup.string()
        // .email('Το email δεν είναι έγκυρο') // temporarily disabled
        .required('Το email είναι απαραίτητο'),
});

export const Login = yup.object({
    email: yup.string()
          .email('Το email δεν είναι έγκυρο')
          .required('Το email είναι απαραίτητο'),
    password: yup.string()
        .required('Ο κωδικός δεν μπορεί να είναι κενός'),
});

export const SignUp = yup.object({
    email: yup.string()
        .email('Το email δεν είναι έγκυρο')
        .required('Το email είναι απαραίτητο'),
    password: yup.string()
        .required('Ο κωδικός δεν μπορεί να είναι κενός')
        .min(6, 'Ο κωδικός πρέπει να έχει τουλάχιστον 6 χαρακτήρες'),
    passwordConfirm: yup.string(),
});

export const UpdatePassword = yup.object({
    oldPassword: yup.string()
        .required('Απαραίτητο πεδίο'),
    newPassword: yup.string()
        .min(6, 'Ο κωδικός πρέπει να έχει τουλάχιστον 6 χαρακτήρες')
        .required('Απαραίτητο πεδίο'),
    newPasswordConfirm: yup.string()
        .min(6, 'Ο κωδικός πρέπει να έχει τουλάχιστον 6 χαρακτήρες')
        .required('Απαραίτητο πεδίο'),
});
