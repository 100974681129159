import React, { useContext, useState } from "react";
import { Alarm } from "../shared/API";
import { AppContext, IAppContext } from "../AppContext";
import moment from "moment";
import { AlarmFormModal } from "./AlarmFormModal";
import { TabSelect } from "./TabSelect";
import { Pagination, Icon } from "semantic-ui-react";
import { Redirect } from "react-router-dom";

interface IConditional {
  children: React.ReactNode;
  condition: boolean;
  alternative?: any;
}

enum FilterSelection { All = "Όλοι", ThirtyDays = "Εντός 30 ημερών", Delayed = "Άμεσα" }

const Conditional = (props: IConditional) => {
  if (props.condition) {
    return props.children;
  } else {
    return props.alternative ? props.alternative : null;
  }
};

export const AlarmsList = () => {
  let appCtx = useContext(AppContext) as IAppContext;
  let [selectedFilter, setSelectedFilter] = useState(FilterSelection.All);
  let [selectedPage, setSelectedPage] = useState(1);
  let [dateAsc, setDateAsc] = useState(true);

  let [selectedAlarmId, setSelectedAlarmId] = useState(-1);

  const setFilter = (filterType: FilterSelection) => {
    setSelectedFilter(filterType);
  };

  const filteredAlarms = (): Array<Alarm> => {
    let sortedAlarms;
    if (dateAsc) {
      sortedAlarms = appCtx.alarms;
    } else {
      sortedAlarms = appCtx.alarms.reverse();
    }
    if (selectedFilter === FilterSelection.All) {
      return sortedAlarms;
    } else if (selectedFilter === FilterSelection.ThirtyDays) {
      return sortedAlarms.filter(({ latestInstallationDate }) =>
        moment(latestInstallationDate)
          .add(2, "year")
          .isBetween(moment(), moment().add(1, "month"))
      );
    } else {
      return sortedAlarms.filter(({ latestInstallationDate }) =>
        moment(latestInstallationDate)
          .add(2, "year")
          .isBefore(moment())
      );
    }
  };

  const pageAlarms = filteredAlarms().slice(
    (selectedPage - 1) * 10,
    selectedPage * 10
  );

  const onPageChange = (e: any, data: any) => setSelectedPage(data.activePage);

  const downArrow = <span>&#8595;</span>;
  const upArrow = <span>&#8593;</span>;

  return (
    <Conditional
      condition={selectedAlarmId === -1}
      alternative={<Redirect to={`alarm/${selectedAlarmId}`} />}
    >
      <div className="app-container">
        <div className="main-actions">
          <TabSelect
            options={[FilterSelection.All, FilterSelection.ThirtyDays, FilterSelection.Delayed]}
            selected={selectedFilter}
            onSelect={setFilter}
          />
          <AlarmFormModal
            model={null}
            trigger={<button>Νέος Συναγερμός</button>}
          />
        </div>
        <div className="app-content">
          <Conditional
            condition={filteredAlarms().length > 0}
            alternative={
              <p style={{ color: "#4c566a", textAlign: "center" }}>
                Δεν βρέθηκαν εγγραφές
        </p>
            }
          >
            <div className="data-view">
              <div className="data-item data-view-header">
                <div
                  className="client-date clickable"
                  onClick={() => setDateAsc(!dateAsc)}
                >
                  Ημερομηνία{dateAsc ? downArrow : upArrow}{" "}
                </div>
                <div className="client-info">Πελάτης </div>
                <div className="comment">Πληροφορίες</div>
                <div className="batteries">Μπαταρίες</div>
                <div className="actions"></div>
              </div>

              {pageAlarms.map(
                ({
                  id_,
                  clientName,
                  mobile,
                  note,
                  latestInstallationDate,
                  ah12,
                  ah23,
                  ah72,
                  isActive
                }) => (
                    <div className="data-item" key={id_} onClick={() => setSelectedAlarmId(id_)}>
                      <div
                        className={`notification ${
                          moment(latestInstallationDate)
                            .add(2, "year")
                            .isBefore(moment())
                            ? "alert"
                            : moment(latestInstallationDate)
                              .add(2, "year")
                              .isBetween(moment(), moment().add(1, "month"))
                              ? "attention"
                              : "normal"
                          }`}
                      ></div>
                      <div className="client-date">
                        {moment(latestInstallationDate).format("DD/MM/YYYY")}
                      </div>
                      <div className="client-info">
                        <div className="client-name">{clientName}</div>
                        <div className="mobile">{mobile}</div>
                      </div>
                      <div className="comment">{note}</div>
                      <div className="batteries">
                        <Conditional condition={ah72 > 0}>
                          <div>{ah72} x 12V 7.2Αh</div>
                        </Conditional>
                        <Conditional condition={ah23 > 0}>
                          <div>{ah23} x 12V 2.3Αh</div>
                        </Conditional>
                        <Conditional condition={ah12 > 0}>
                          <div>{ah12} x 12V 1.2Αh</div>
                        </Conditional>
                      </div>
                      <div className="actions">
                        <Icon name="chevron right" />
                      </div>
                    </div>
                  )
              )}
            </div>
          </Conditional >
          <Conditional condition={filteredAlarms().length > 10}>
            <Pagination
              firstItem={null}
              lastItem={null}
              onPageChange={onPageChange}
              defaultActivePage={1}
              totalPages={Math.ceil(filteredAlarms().length / 10)}
              floated="right"
            />
          </Conditional>
        </div >
      </div >
    </Conditional>
  );
};
