import React, { useState, useContext } from 'react';
import { LogEntry,postLogEntry, putLogEntry } from '../shared/API';
import * as R from 'ramda';
import moment from 'moment';
import Calendar from "rc-calendar";
import elGR from "rc-calendar/lib/locale/el_GR";
import "rc-calendar/assets/index.css";
import { getAuth } from '../Utilities';
import { Form, FormField, Message } from 'semantic-ui-react';
import DatePicker from "rc-calendar/lib/Picker";
import { AppContext, IAppContext } from "../AppContext";
import { useFormik } from 'formik';
import { NewLogEntry as NewLogEntrySchema} from '../shared/Schemas';

type FormProps = {
    model: LogEntry | null;
    alarmId: number;
    triggerOnSuccess: () => void;
}

interface FormValues {
    installationDate: Date;
    installationNote: string;
}

enum Status {
    Normal,
    Error
}

export const LogEntryForm = (props: FormProps) => {

    const initValues: FormValues = {
        installationDate: props.model ? props.model.installationDate : new Date(),
        installationNote: props.model ? props.model.installationNote : ''
    }

    let [st, setSt] = useState(Status.Normal);

    const appCtx = useContext(AppContext) as IAppContext;

    const handleSubmit = (installationNote: string, installationDate: Date) => {
        if (R.isNil(props.model)) {
            postLogEntry({ installationDate, installationNote }, props.alarmId, getAuth())
                .then((response) => {
                    appCtx.getAlarmsCtx();
                    props.triggerOnSuccess();
                })
                .catch((err) => {
                    setSt(Status.Error)
                });
        } else {
            putLogEntry({ ...props.model, installationNote, installationDate } as LogEntry, getAuth())
                .then((response) => {
                    appCtx.getAlarmsCtx();
                    props.triggerOnSuccess();
                })
                .catch((err) => {
                    setSt(Status.Error)
                });
        }
    }

    const formik = useFormik({
        initialValues: initValues,
        validationSchema: NewLogEntrySchema,
        onSubmit: (values) => handleSubmit(values.installationNote, values.installationDate),
    });

    const clearFormMessages = () => {
        if (st !== Status.Normal) {
            setSt(Status.Normal);
        }
    };

    const calendar = (
        <Calendar
            locale={elGR}
            value={moment(formik.values.installationDate)}
            onChange={date => {
                formik.getFieldHelpers('installationDate').setValue(date);
            }}
        />
    );

    const dateFormat = "DD/MM/YYYY";

    return (
        <Form
            error={st === Status.Error}
            size="small"
            onSubmit={formik.handleSubmit}
        >
            <FormField>
                <label>Ημερομηνία</label>
                <DatePicker
                    required
                    animation="slide-up"
                    calendar={calendar}
                    value={moment(formik.values.installationDate)}
                >
                    {() => (
                        <input
                            style={{ width: 110 }}
                            readOnly
                            disabled={false}
                            value={moment(formik.values.installationDate).format(dateFormat)}
                            placeholder="ημερομηνία εγκατάστασης"
                            onFocus={clearFormMessages}
                        />
                    )}
                </DatePicker>
            </FormField>
            <Form.Input
                fluid
                label="Σημείωση"
                placeholder="Σημείωση"
                name="installationNote"
                value={formik.values.installationNote}
                onChange={formik.handleChange}
                onFocus={clearFormMessages}
            />
            <Message
                error
                header="Ανεπιτυχής καταχώρηση"
                content="Κάτι δεν πήγε καλά!"
            />
            <Message
                success
                header="Eπιτυχής καταχώρηση"
                content={
                    !R.isNil(props.model)
                        ? "Οι αλλαγές αποθηκεύτηκαν επιτυχώς"
                        : ""
                }
            />
            <div className="modal-actions">
                <button className="fluid">
                    {!R.isNil(props.model) ? "Ενημέρωση" : "Καταχώρηση"}
                </button>
            </div>
        </Form>
    );
}